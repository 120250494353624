<template>
  <div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <div class="time">
          <span class="demonstration" style="width: 80px; text-align: end"
            >时间：</span
          >
          <el-date-picker
            v-model="time"
            type="monthrange"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            value-format="yyyy-MM"
            @change="changTime"
          >
          </el-date-picker>
        </div>
        <div class="_Select" style="padding-left: 30px">
          <span>检查类型：</span>
          <el-select
            v-model="examRange"
            placeholder="请选择"
            @change="changeType"
          >
            <el-option
              v-for="item in [
                '区域检查',
                '文件检查',
                '考核细则',
                '内外审问题点',
              ]"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="table">
        <el-table
          :height="elementHeight"
          :data="tableList"
          style="width: 100%"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          border
          id="table"
        >
          <el-table-column prop="question" label="问题"> 
            <template slot-scope="scope">
              <span>{{scope.row.question?scope.row.question:scope.row.questionRemark}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="answer" label="答案" v-if="examRange != '内外审问题点'"> 
            <template slot-scope="scope">
              <span>{{scope.row.answer?scope.row.answer:scope.row.improvement?scope.row.improvement:scope.row.correctMeasure}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="_Pagination" v-if="pageShow">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../../api/http";
export default {
  data() {
    return {
      time: [],
      examRange: "区域检查",
      tableList: [],
      // 分页
      currentPage: 1,
      pageSize: 10,
      count: 0,
      pageShow: true,
      elementHeight: 0,
    };
  },
  mounted() {
    this.getElementHeight();
    var nextTime = new Date();
    nextTime.setMonth(nextTime.getMonth() + 1);
    this.getTableList(new Date().toISOString().slice(0,7), nextTime.toISOString().slice(0,7));
    this.time = [new Date().toISOString().slice(0,7), nextTime.toISOString().slice(0,7)];
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 70 + 72 );
      });
    },
    changTime(e) {
      this.pageShow = false;
      this.currentPage = 1;
      this.getTableList(e[0],e[1]);
    },

    changeType() {
      this.pageShow = false;
      this.currentPage = 1;
      this.getTableList(this.time[0], this.time[1]);
    },
    getTableList(formData, toData) {
      this.tableList = [];
      var url = "";
      switch (this.examRange) {
        case "区域检查":
          url = "/api/RecentStatistics/GetRecentAreaAuditPage";
          break;
        case "文件检查":
          url = "/api/RecentStatistics/GetRecentFileAuditPage";
          break;
        case "考核细则":
          url = "/api/RecentStatistics/GetRecentExamineRulesPage";
          break;
        case "内外审问题点":
          url = "/api/RecentStatistics/GetRecentInOutAuditPage";
          break;
      }
      get(
        url +
          "?FromDate=" +
          formData +
          "&ToDate=" +
          toData +
          "&limit=" +
          this.pageSize +
          "&page=" +
          this.currentPage
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.tableList = resp.data;
            this.count = resp.count;
          }
          this.pageShow = true;
        })
        .catch(() => {
          this.pageShow = true;
        });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getTableList(this.time[0], this.time[1]);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList(this.time[0], this.time[1]);
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  border-top: 1px solid rgb(224, 224, 224);
  padding: 5px 20px;
}
.time {
  display: flex;
  align-items: center;
}
.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}
/deep/ .el-range-separator {
  line-height: 28px !important;
}
// 分页
._Pagination {
  position: absolute;
  bottom: 10px;
  width: 98%;
  text-align: end;
}
</style>